import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { sm } from 'utils/constants';
import { useViewport } from 'use-viewport';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import { getSoldPercent } from 'utils/calculation';
import Spacer from 'common/components/Spacer/Spacer';
import { checkLive } from 'utils/live-check';

type DropType = {
  __typename: string;
  id: string;
  slug: string;
  title: string;
  fileByCoverId: {
    __typename: string;
    url: string;
  };
  releaseAt: string;
  audioLengthMs: number;
  actualBitPrice: number;
  bitCount: string;
};
interface DropDataProps {
  data: DropType;
}

const DropData: FC<DropDataProps> = ({ data }) => {
  const navigate = useNavigate();
  const viewport = useViewport();
  const is_live = checkLive(data.releaseAt);

  const getDateTextSize = (title: string) => {
    const d = new Date(title).toLocaleDateString('en-GB', {
      year: '2-digit',
      month: 'long',
      day: 'numeric',
    });
    return viewport.width <= 576 ? (d.length > 12 ? 'fz30' : 'fz34') : 'fz36';
  };

  return (
    <DropContainer>
      <ButtonLink
        onClick={() => navigate(`/artist/releases/summary?id=${data.id}`)}>
        <DropImage src={data.fileByCoverId.url} />
      </ButtonLink>

      <InfoContainer>
        <ButtonLink
          onClick={() => navigate(`/artist/releases/summary?id=${data.id}`)}>
          <Typography
            text={new Date(data.releaseAt)
              .toLocaleDateString('en-GB', {
                year: '2-digit',
                month: 'long',
                day: 'numeric',
              })
              .toLowerCase()}
            fontColor={theme.colors.yellow}
            fontSize={getDateTextSize(data.releaseAt)}
            fontWeight="bold"
          />

          <Spacer height={10} />

          <Typography
            text={data.title}
            fontSize={viewport.width < sm ? 'fz18' : 'fz24'}
            fontWeight="light"
          />
        </ButtonLink>
        <Spacer height={45} />
        {is_live.now_epoch > is_live.date_epoch ? (
          <>
            <Typography
              text={
                getSoldPercent(
                  data.audioLengthMs,
                  1,
                  JSON.parse(data.bitCount).sold_bits_inverted,
                ).toString() + '% Sold'
              }
              fontColor={theme.colors.white}
              fontSize={'fz16'}
              fontWeight="light"
            />
          </>
        ) : (
          <>
            <Typography
              text={'sale not started'}
              fontColor={theme.colors.white}
              fontSize={'fz16'}
              fontWeight="light"
            />
          </>
        )}

        <Spacer height={5} />
        {is_live.now_epoch > is_live.date_epoch ? (
          <>
            <ButtonLink onClick={() => navigate(`/artist/insights`)}>
              <Typography
                text={'view analytics'}
                fontColor={theme.colors.white}
                fontSize={'fz16'}
                fontWeight="bold"
              />
            </ButtonLink>
          </>
        ) : (
          <>
            <ButtonLink onClick={() => navigate(`/artist/waitlist-stats`)}>
              <Typography
                text={'view waitlist details'}
                fontColor={theme.colors.white}
                fontSize={'fz16'}
                fontWeight="bold"
              />
            </ButtonLink>
          </>
        )}
      </InfoContainer>
    </DropContainer>
  );
};

export const DropContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 30px;
`;

const DropImage = styled.img`
  width: 145px;
  height: 145px;

  margin-right: -10px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 145px;
`;

const ButtonLink = styled.div`
  cursor: pointer;
`;

export default DropData;
